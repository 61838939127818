@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Regular';
    src: url(assets/fonts/iannnnn-DOG-Regular.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Light';
    src: url(assets/fonts/iannnnn-DOG-Light.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Bold';
    src: url(assets/fonts/iannnnn-DOG-Bold.ttf) format('truetype');
  }
}
@layer utilities {
  .fontfit1 {
    @apply text-fit1;
  }
  .fontfit2 {
    @apply text-fit2;
  }
  .fontfit2-5 {
    @apply text-fit2-5;
  }
  .fontfit3 {
    @apply text-fit3;
  }
  .fontfit3-5 {
    @apply text-fit3-5;
  }
  .fontfit4 {
    @apply text-fit4;
  }
  .fontfit4-5 {
    @apply text-fit4-5;
  }
  .fontfit5 {
    @apply text-fit5;
  }
  .fontfit5-5 {
    @apply text-fit5-5;
  }
  .fontfit6 {
    @apply text-fit6;
  }
  .fontfit6-5 {
    @apply text-fit6-5;
  }
  .fontfit7 {
    @apply text-fit7;
  }
  .fontfit7-5 {
    @apply text-fit7-5;
  }
  .fontfit8 {
    @apply text-fit8;
  }
  .fontfit8-5 {
    @apply text-fit8-5;
  }
  .fontfit9 {
    @apply text-fit9;
  }
  .fontfit9-5 {
    @apply text-fit9-5;
  }
  .fontfit10 {
    @apply text-fit10;
  }
  .fontfit10-5 {
    @apply text-fit10-5;
  }
  .fontfit11 {
    @apply text-fit11;
  }
  .fontfit11-5 {
    @apply text-fit11-5;
  }
  .fontfit12 {
    @apply text-fit12;
  }
}